@font-face {
    font-family: Impact;
    src: url("../fonts/impact.ttf") format("ttf");
}

/* General */

body {
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

section {
    padding-top: 40px;
    padding-bottom: 40px;
}

a {
    color: #0064B1;
    text-decoration: none;
}

section h2 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #0064B1;
    text-transform: uppercase;
}

section h3 {
    margin-bottom: 45px;
    font-size: 35px;
    line-height: 50px;
    font-weight: 700;
    color: #131D3B;
    text-transform: capitalize;
    opacity: 0.8;
    position: relative;
}

section h3::after {
    display: block;
    position: absolute;
    width: 5.625rem;
    height: .5rem;
    content: '';
    background: linear-gradient(to right, #ffffff 0, #ffffff 10%, #ffc942 100%)!important;
    border-radius: 10px;
    border: 2px solid #ffc942;
}

section h3.type-2::after {
    left: 50%;
    margin-left: -45px;
}

section p {
    text-align: justify;
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    color: #131D3B;
    opacity: 0.8;
}

.section-bg {
    background-color: #F9F9FD;
}

.entry {
    padding-top: 80px;
}

.cmt-1 {
    margin-top: -52px;
}

.pb-unset {
    padding-bottom: unset;
}

.btn-primary {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #0064B1;
    border-color: #0064B1;
    color: #fff;
}

.btn-secondary {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #131D3B;
}

.btn-primary:hover {
    background-color: #0064B1;
    border-color: #0064B1;
    box-shadow: 0px 10px 30px #1264B14B;
}

.mt-unset {
    margin-top: unset !important;
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.MuiTypography-root {
    color: #000;
    font-weight: 500 !important;
}

.header {
    background-color: #0064B1;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    text-transform: uppercase;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #FDC83B;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 40px;
}

.navbar-expand-lg .navbar-nav .nav-link:first-child {
    margin-left: unset;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #FFFFFF;
    border-bottom: 2px solid #FFCC2F;
}

.breadcrumbs {
    text-align: center;
    padding: 96px 0;
    min-height: 100%;
    background: linear-gradient(111deg, rgb(34 32 30 / 20%) 60%, rgb(34 32 30 / 20%) 60%, rgb(34 32 30 / 20%) 60%), url(../images/conmix-infra-ready-mixed-concrete.png);
    background-size: cover;
    background-position: center;
}

.breadcrumbs h1 {
    font: normal normal bold 30px/46px Poppins;
    color: #FFFFFF;
    text-transform: capitalize;
}

.breadcrumbs ul {
    padding-left: unset;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.breadcrumbs ul li a {
    font: normal normal 600 14px/40px Poppins;
    color: #FDC83B;
    text-transform: uppercase;
}

.breadcrumbs ul li a:hover {
    color: #03abee !important;
}

.breadcrumbs ul li+li {
    padding-left: 10px;
    font: normal normal 600 14px/40px Poppins;
    color: #FDC83B;
    text-transform: uppercase;
}

.breadcrumbs ul li+li::before {
    display: inline-block;
    padding-right: 10px;
    content: "/";
    font: normal normal 600 14px/40px Poppins;
    color: #FDC83B;
    text-transform: uppercase;
}

.slider h1, .slider h2 {
    text-align: left;
    font-size: 60px;
    color: #FFFFFF;
}

.slider .carousel-caption {
    top: 30%;
    left: 15%;
    white-space: nowrap;
    background: linear-gradient(111deg, rgb(255 204 47 / 80%) 60%, rgb(255 204 47 / 80%) 60%, rgb(255 204 47 / 80%) 60%), url(../images/about-conmixinfra.png);
    background-position: top right;
    bottom: unset;
    color: #fff;
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    padding-top: 100px;
    padding-left: 100px;
    padding-bottom: 100px;
}

.slider .carousel-indicators .active {
    width: 15px !important;
    height: 15px !important;
    background-color: #fff !important;
}

.slider .carousel-indicators {
    position: absolute;
    right: unset;
    bottom: 0;
    left: unset;
    z-index: 2;
    display: unset;
    justify-content: unset;
    padding: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: 5%;
    list-style: none;
    top: 46%;
}

.slider .carousel-indicators [data-bs-target] {
    border-radius: 50%;
    display: block;
    margin: -7px;
    box-sizing: content-box;
    /* flex: 0 1 auto; */
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 0px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #f9d154;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1;
    transition: opacity .6s ease;
}

.bg-1 {
    background-image: url(../images/bg-1.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    padding-bottom: unset;
}

.bg-2 {
    padding: unset;
    background-image: url(../images/bg-2.png);
    background-position: top center;
    background-repeat: no-repeat;
}

.bgc-2 {
    background-color: #ffcb2fe8;
}

.bgc-2 .container {
    padding-top: 60px;
    padding-bottom: 60px;
}

.touch h2 {
    font-size: 35px;
    font-weight: 700;
    color: #1264B1;
    text-transform: capitalize;
}

.benefits {
    margin-top: 25px;
    padding: 22px;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 30px #7B8ECB29;
}

marquee {
    margin-bottom: -4px;
}

.benefits img {
    float: left;
    border-radius: 50%;
    padding: 20px;
    background-color: #F9F9FD;
}

.benefits h4 {
    margin-left: 90px;
    font-size: 18px;
    font-weight: 600;
    color: #131D3B;
    text-transform: capitalize;
    opacity: 0.8;
}

.benefits p {
    margin-left: 90px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #131D3B;
    opacity: 0.8;
}

.benefits a {
    margin-left: 90px;
    font-size: 16px;
    font-weight: 500;
    color: #1264B1;
}

.team {
    text-align: center;
    position: relative;
}

.team img.design {
    position: absolute;
    right: 80px;
}

.team img.profile {
    border-radius: 50%;
    margin-bottom: 25px;
}

.team h4 {
    font-size: 20px;
    font-weight: 600;
    color: #131D3B;
    opacity: 0.8;
}

.team p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #131D3B;
    opacity: 0.8;
}

.des-2 {
    top: -50px;
    left: -75px;
    position: absolute;
}

#footer {
    padding-top: 45px;
    padding-bottom: 1px;
    background-color: #0064B1;
}

#footer svg {
    color: #FFF;
    float: left;
    margin-right: 5px;
}

#footer .social ul {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

#footer .social ul li svg {
    margin: 5px;
    font-size: 40px;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid #FFCC2F;
    color: #FFCC2F;
}

#footer h5 {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 600;
    color: #FFCC2F;
    text-transform: uppercase;
}

#footer h5::after {
    display: inline-block;
    width: 2rem;
    height: .2rem;
    margin-left: 8px;
    margin-bottom: 4px;
    content: '';
    background-color: #ffc942!important;
    border-radius: 10px;
    border: 1px solid #ffc942;
}

#footer p {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    padding-left: 30px;
}

#footer ul {
    list-style: none;
    padding-left: unset;
}

#footer ul li a, #footer a {
    line-height: 25px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

#footer ul li a:hover, #footer a:hover {
    color: #ffc942;
}

.copyright p, .copyright a {
    padding-left: unset !important;
    color: #fff;
}

#footer .links {
    text-align: right;
}

.testimonials svg {
    bottom: 0;
    color: #000;
}

.testimonials svg:hover {
    color: #ffc942;
}

.testimonials .slick-prev, .testimonials .slick-next {
    top: unset;
    bottom: -35px;
}

.testimonials .slick-prev {
    left: 47%;
}

.testimonials .slick-next {
    right: 47%;
}

.testimonial {
    margin: 25px;
    padding: 30px;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 20px #7B8ECB29;
}

.testimonial .slick-slide {
    position: relative;
}

.testimonial svg {
    margin-top: -12%;
    border-radius: 50%;
    z-index: 2;
    background-color: #ffc942;
    color: #fff;
    font-size: 50px;
}

.testimonial svg:hover {
    background-color: #ffc942;
    color: #fff;
}

.testimonial p {
    font-size: 16px;
    line-height: 35px;
    color: #131D3B;
    opacity: 0.8;
}

.testimonial h4 {
    font-size: 18px;
    color: #0064B1;
    text-transform: capitalize;
}

.contact-info {
    text-align: center;
}

.contact-info svg {
    margin: 0 10px 12px 10px;
    color: #0064B1;
}

.contact-info h4 {
    font: normal normal 600 18px/25px Poppins;
    color: #131D3B;
    opacity: 0.8;
}

.contact-info .icon {
    margin-top: 40px;
}

.contact-info p, .contact-info p a {
    text-align: unset;
    font: normal normal normal 16px/21px Poppins;
    color: #000000;
    opacity: 0.8;
}

.contact-info .icon:before, .contact-info .icon:after {
    display: inline-block;
    content: "";
    border-top: 1px solid #0064B1;
    width: 48px;
    transform: translateY(-0.6rem);
}

.contact-form {
    padding: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #53749A29;
}

.contact-form .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    margin-top: 30px !important;
}

.contact-form h2 {
    margin-top: 14px;
    padding-bottom: 20px;
}

.contact-form .form-control, .contact-form button {
    margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #FFF !important;
    color: #03abee !important;
}

.contact-form .MuiButton-root {
    font-size: 14px;
    border: none;
    padding: 10px 20px!important;
    background-color: #0064B1;
    border-radius: 6px;
    color: #fff!important;
}

.contact-form .MuiButton-root:hover {
    background-color: transparent;
    color: #0064B1!important;
    border: 1px solid #0064B1;
}

.machinery {
    margin-bottom: 60px;
}

.slick-slide .machinery img {
    display: unset !important;
}

.machinery .count h4 span {
    font-family: Impact;
    font-size: 3rem;
    color: #C4D9EC;
}

.machinery .count h4 {
    font-weight: 600;
    font-size: 1.1rem;
    color: #131D3B;
    opacity: 0.9;
}

.pri-policy {
    margin-top: 50px;
    margin-bottom: 50px;
}

.sitemap ul {
    list-style: none;
    padding-left: unset;
}

.sitemap ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.sitemap ul li a {
    color: #000000;
}

.sitemap ul li a:hover {
    color: #FFCC2F;
}

.gallery .MuiTab-root {
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.gallery .MuiTab-root.Mui-selected {
    color: #000000;
}

.gallery .MuiTabs-indicator {
    background-color: #FFCC2F;
}

.gallery p {
    opacity: 1;
}

.gallery img {
    margin-bottom: 25px;
}

.info h4 span {
    font-family: Impact;
    font-size: 16rem;
    color: #FFCC2F;
}

.info h4 {
    margin-top: -150px;
    margin-left: -56px;
    font-weight: 700;
    font-size: 1.3rem;
    color: #131D3B;
    opacity: 0.8;
}

.client img {
    filter: grayscale();
    opacity: 0.8;
}

.client:hover img {
    filter: unset;
    opacity: 1;
}

.menu-footer {
    position: fixed;
    font-size: 13px;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffffd1;
    z-index: 999;
    border-top: 1px solid #ccc;
}

.menu-footer a {
    color: #374957 !important;
}

.menu-footer a.active {
    color: #03ABED !important;
}

.menu-footer .b-right {
    border-right: 1px solid #ccc;
}